<template>
  <div class="p-grid p-jc-center">
    <div class="p-col-8">
      <Fieldset legend="ข้อเสนอแนะ/ข้อมูลที่อยากได้จากระบบ">
        <div style="margin-bottom: 5px">
          <div class="p-d-flex p-jc-between">
            <Button
              label="ย้อนกลับ"
              icon="pi pi-arrow-left"
              class="p-button-sm p-button-warning"
              @click="() => $router.back(-1)"
            />
            <Button
              label="ดูรายการข้อเสนอแนะ"
              icon="pi pi-comments"
              class="p-button-sm p-button-success"
              @click="() => $router.push({ path: '/suggests' })"
            />
          </div>
        </div>

        <!-- ชื่อ-นามสกุล -->
        <form @submit.prevent="saveSuggestion" id="validity">
          <div class="p-fluid">
            <div class="p-fluid p-grid">
              <label for="userLevel" class="p-col-12 p-md-3 p-text-right"
                >ชื่อ-นามสกุล
              </label>
              <div class="p-field p-col-12 p-md-9">
                <InputText
                  v-model="suggest.name"
                  placeholder="ชื่อ-นามสกุล"
                  :required="true"
                />
              </div>
            </div>
            <!-- /ชื่อ-นามสกุล -->

            <!-- พื้นที่/ทีมวิจัย-->
            <div class="p-fluid p-grid">
              <label for="userLevel" class="p-col-12 p-md-3 p-text-right"
                >พื้นที่/ทีมวิจัย
              </label>
              <div class="p-field p-col-12 p-md-9">
                <Dropdown
                  id="areaCode"
                  v-model="suggest.areaCode"
                  :options="areas"
                  optionLabel="areaName"
                  optionValue="areaCode"
                  placeholder="พื้นที่/ทีมวิจัย"
                />
              </div>
            </div>
            <!-- /หน่วยงาน/พื้นที่ -->

            <!-- ภาคีเครือข่ายร่วมพัฒนาผู้สูงอายุ-->
            <div
              class="p-fluid p-grid"
              v-if="['01', '02', '03', '04'].includes(suggest.areaCode)"
            >
              <label for="userLevel" class="p-col-12 p-md-3 p-text-right"
                >ภาคีเครือข่ายร่วมพัฒนาผู้สูงอายุ
              </label>
              <div class="p-field p-col-12 p-md-9">
                <Dropdown
                  id="areaCode"
                  v-model="suggest.partnerCode"
                  :options="partners"
                  optionLabel="partnerName"
                  optionValue="partnerCode"
                  placeholder="ภาคีเครือข่ายร่วมพัฒนาผู้สูงอายุ"
                />
              </div>
            </div>
            <!-- /ภาคีเครือข่ายร่วมพัฒนาผู้สูงอายุ -->

            <!-- ข้อเสนอแนะ -->
            <div class="p-fluid p-grid">
              <label for="userLevel" class="p-col-12 p-md-3 p-text-right"
                >ข้อเสนอแนะ</label
              >
              <div class="p-field p-col-12 p-md-9">
                <Textarea
                  rows="3"
                  style="width: 100%"
                  v-model="suggest.suggestion"
                  placeholder="ข้อเสนอแนะ อย่างน้อย 10 ตัวอักษร"
                  :required="true"
                />
              </div>
            </div>
            <!-- /ข้อเสนอแนะ -->

            <!-- recaptcha -->
            <div class="p-fluid p-grid">
              <label for="userLevel" class="p-col-12 p-md-3" />
              <div class="p-col-12 p-md-8">
                <RandomCaptcha @onCorrect="onRecaptcha" />
              </div>
            </div>
            <!-- /recaptcha -->
          </div>

          <div class="p-grid">
            <label class="p-col-12 p-md-3 p-text-right" />
            <div class="p-lg-9 p-md-6">
              <Button
                type="submit"
                label="บันทึกข้อมูล"
                class="p-button-success"
                icon="pi pi-save"
                iconPos="left"
                :disabled="isRobotic"
              />
              <Button
                label="ยกเลิก"
                class="p-button-warning"
                icon="pi pi-undo"
                iconPos="left"
                @click="() => $router.back(-1)"
              />
            </div>
          </div>
        </form>
      </Fieldset>
    </div>
  </div>
  <ConfirmDialog :style="{ width: '400px' }" />
</template>

<script>
import { orderBy } from 'lodash'
// 6LdH2LcaAAAAAJ9Ne07llAdjS9OxYOeXL2mDacKv
// app.use(VueReCaptcha, { siteKey: '6LdH2LcaAAAAAEdGiss8ubAN0iqNG-lya5_4QLpd' })
//import Required from '@/components/util/Required'
import { initCustomValidity } from '@/HTMLElement'
import RandomCaptcha from '@/components/util/RandomCaptcha'
export default {
  layout: 'Public',
  data() {
    return {
      suggest: {},
      recaptcha: null,
      isRobotic: true
    }
  },
  computed: {
    areas() {
      const { areas } = this.$store.state.constance
      const _unsorts = Object.keys(areas).map((code) => {
        return { areaCode: code, areaName: areas[code] }
      })
      return orderBy(_unsorts, ['areaCode'], ['asc'])
    },
    partners() {
      const { partners } = this.$store.state.constance
      const _unsorts = Object.keys(partners).map((code) => {
        return { partnerCode: code, partnerName: partners[code] }
      })
      return orderBy(_unsorts, ['partnerCode'], ['asc'])
    }
  },
  mounted() {
    initCustomValidity('validity')
  },
  methods: {
    onRecaptcha(value) {
      console.log('value ::==', value)
      this.isRobotic = !value
    },
    async saveSuggestion() {
      console.log('saveSuggestion ::')

      const { areaCode, partnerCode } = this.suggest

      if (
        !areaCode ||
        (['01', '02', '03', '04'].includes(areaCode) && !partnerCode)
      ) {
        let message = 'กรุณาใส่ข้อมูล '
        if (!areaCode) {
          message += ' พื้นที่/ทีมวิจัย '
        } else {
          if (['01', '02', '03', '04'].includes(areaCode) && !partnerCode) {
            message += ' ภาคีเครือข่ายร่วมพัฒนาผู้สูงอายุ '
          }
        }

        message += ' ก่อนการบันทึกข้อมูล'
        this.$toast.add({
          severity: 'error',
          summary: 'สถานะการทำรายการ ',
          detail: message,
          life: 3000
        })
      } else {
        this.$confirm.require({
          ...this.$primevue.config.locale,
          message:
            'ยืนยันการบันทึกข้อมูล ข้อเสนอแนะ/ข้อมูลที่อยากได้จากระบบ ใช่หรือไม่ กด ตกลง เพื่อยืนยันการบันทึก หรือ ยกเลิก เพื่อยกเลิกการทำรายการ',
          reject: async () => {
            const {
              status,
              code,
              data,
              invalidCode
            } = await this.$store.dispatch('suggest/saveSuggests', this.suggest)
            if (200 == code) {
              this.$toast.add({
                severity: 'success',
                summary: 'สถานะการทำรายการ ',
                detail:
                  'บันทึกข้อมูล ข้อเสนอแนะ/ข้อมูลที่อยากได้จากระบบ เข้าระบบเป็นที่เรียบร้อยแล้ว',
                life: 3000
              })
              setTimeout(() => {
                this.$router.go(0)
              }, 1000)
            } else {
              this.$toast.add({
                severity: 'error',
                summary: 'สถานะการทำรายการ',
                detail:
                  'บันทึกข้อมูล ข้อเสนอแนะ/ข้อมูลที่อยากได้จากระบบ ไม่สำเร็จ กรุณาติดต่อผู้ดูแลระบบ',
                life: 3000
              })
            }
          }
        })
      }
    }
  },
  components: {
    //Recaptcha,
    RandomCaptcha
    //Required
  }
}
</script>

<style>
</style>
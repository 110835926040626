<template>
  <div class="p-grid">
    <div class="p-col-6 p-md-3">
      <div ref="canvas"></div>
      <a style="cursor: pointer" @click="changeCapcharAgain">เปลี่ยนคำ</a>
    </div>
    <div class="p-col-6 p-md-4">
      <div class="p-inputgroup">
        <InputText placeholder="ใส่รหัสให้ถูกต้อง" v-model="state.recaptcha" />
        <span
          class="p-inputgroup-addon"
          @click="submitCaptcha"
          v-if="state.isSubmit"
        >
          <i class="pi" :class="[isCorrect ? 'pi-check' : 'pi-times']"></i>
        </span>
      </div>
    </div>
    <label
      v-if="!state.isCorrect"
      style="color: red; font-weight: bold"
      class="p-col-6 p-md-4"
      >รหัสไม่ถูกต้อง</label
    >
  </div>
</template>

<script>
import { ref, onMounted, reactive, watch } from 'vue'
export default {
  name: 'rendomCaptcha',
  emits: ['onCorrect'],
  setup(props, { emit }) {
    const canvas = ref(null)
    const state = reactive({
      code: null,
      recaptcha: null,
      isSubmit: false,
      isCorrect: true
    })

    watch(
      () => state.recaptcha,
      (newV, oldV) => {
        console.log('newV ::==', newV)
        state.isSubmit = newV?.length >= state.code?.length
        if (newV?.length > 0 && newV?.length < state.code?.length) {
          state.isCorrect = false
        }
        if (state.isSubmit) {
          submitCaptcha()
        }
      }
    )

    onMounted(() => {
      createCaptcha()
    })

    const changeCapcharAgain = () => {
      createCaptcha()
    }

    const submitCaptcha = () => {
      console.log('state ::==' + JSON.stringify(state))
      state.isCorrect = state.code == state.recaptcha
      if (state.isCorrect) {
        //alert('correct')
        emit('onCorrect', true)
      } else {
        //alert('in correct')
        emit('onCorrect', false)
      }
    }

    const createCaptcha = () => {
      //clear the contents of captcha div first
      canvas.value.innerHTML = ''
      var charsArray =
        '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ@!#$%^&*'
      var lengthOtp = 6
      var captcha = []
      for (var i = 0; i < lengthOtp; i++) {
        //below code will not allow Repetition of Characters
        var index = Math.floor(Math.random() * charsArray.length + 1) //get the next character from the array
        if (captcha.indexOf(charsArray[index]) == -1)
          captcha.push(charsArray[index])
        else i--
      }
      var canv = document.createElement('canvas')
      canv.id = 'captcha'
      canv.width = 100
      canv.height = 50
      var ctx = canv.getContext('2d')
      ctx.font = '25px Georgia'
      ctx.strokeText(captcha.join(''), 0, 30)
      //storing captcha so that can validate you can save it somewhere else according to your specific requirements
      state.code = captcha.join('')
      console.log('state.code ::==', state.code)
      console.log('canvas ::==', canvas)
      canvas.value.appendChild(canv) // adds the canvas to the body element
    }

    return {
      canvas,
      state,
      createCaptcha,
      submitCaptcha,
      changeCapcharAgain
    }
  }
}
</script>

<style>
canvas {
  /*prevent interaction with the canvas*/
  pointer-events: none;
}
</style>